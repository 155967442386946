body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #F5F6FA;
  height: 100vh;
}

.table td, .table th {
  padding: .5rem !important;
  font-size: 14px;
}

.btn-outline-secondary {
  border-color: #ced4da !important;
}